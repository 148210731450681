import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CookieBannerComponent } from './shared/cookie-banner/cookie-banner.component';

import { HomeComponent } from './home/home.component';
import { Four04Component } from './404/four04.component';

const routes: Routes = [
  { path: '', component: CookieBannerComponent, outlet: 'cookie' },
  { path: '', component: HomeComponent },
  { path: '**', component: Four04Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
