import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@src/environments/environment';

import { Message } from '../interfaces/message';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  constructor(
    private http: HttpClient
  ) { }

  private handleError<T>(data?: any) {
    return (error: any): Observable<T> => {
      if ((
        error.status === 0 ||
        error.status === 500
      ) && 
        data
      ) {
        try {
          return this.sendMessage(data);
         } catch {
          console.error(error);
          return of(error as T);
         }
      }

      console.error(error);
      return of(error as T);
    };
  }

  sendMessage(body: Message): Observable<any> {
    const url = environment.contactURL,
    
          headers = new HttpHeaders({
            'Content-Type': ' text/plain',
            'Access-Control-Allow-Origin': '*'
          });

    return this.http.post(
      url, 
      JSON.stringify(body),
      { headers }
    )
    .pipe(
      catchError(this.handleError<any>(body))
    )
  }
}
