<section id="home-hero">
	<div class="section-main">
		<div id="hero-content">
			<div id="hero-top">
				<img id="hero-logo" src="../../../assets/images/logo-blk-001.svg" width="60" />
				<div id="hero-copy">
					<h2>Hi, my name is Nick <br />and I&rsquo;m a digital creator.</h2>
					<p>
						Basically meaning I do web and email development <br class="hide" />with a dash of digital design.
					</p>
				</div>
			</div>
			<nav id="hero-nav">
				<li><a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" fragment="my-work" [attr.data-content]="'Hero Nav My Work'" (click)="clickAnchor('my-work', $event); false;">My Work</a></li>
				<li><a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" fragment="services" [attr.data-content]="'Hero Nav Services'" (click)="clickAnchor('services', $event); false;">Services</a></li>
				<li><a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" fragment="my-story" [attr.data-content]="'Hero Nav My Story'" (click)="clickAnchor('about', $event); false;">My Story</a></li>
				<li><a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" fragment="contact" [attr.data-content]="'Hero Nav Contact'" (click)="clickAnchor('contact', $event); false;">Contact</a></li>
			</nav>
		</div>
	</div>
	<a id="down-arrow" [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" fragment="my-work" (click)="clickAnchor('my-work', $event); false;"><img src="../../../assets/images/down-arrow-tan-001.svg" width="60" alt="Hero Down-Arrow" /></a>
</section>
