export const ctfConfig = {
	space: '1sl9m3guoua9',
	accessToken: 'S4R90fMDI5XWE10g7GPT3aZpg43yVUkNzcg5m413RCY',
	contentTypeIDs: {
		entries: 'entries',
		about: 'about',
		services: 'services'
	},
	aboutID: '6y6L9GtkuMda6vHktN0WxA',
	servicesIDs: {
		email: '4iuFEZnriqci20ACsNzV9v',
		frontEnd: '7gEoc8b9gdUALzE0Mf9Di9',
		design: '3D9Hsk1ysdRiFMfuv8D6N7'
	}
}