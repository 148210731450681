import { Component, OnInit, AfterViewChecked, ElementRef, HostListener } from '@angular/core';

import { AnalyticsService } from '@src/app/shared/services/fb-analytics.service';
import { GeneralService, debounce } from '@src/app/shared/services/general.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';

@Component({
  selector: 'nh-home-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})

export class HeroComponent implements OnInit, AfterViewChecked {
  isBigScrn: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService,
    private heroHTML: ElementRef
  ) { }

  @debounce(1500)
  scrollSnap($event?: any): void {
    if (this.generalService.isBigScrn)
      this.generalService.scrollSnap(this.heroHTML.nativeElement, null, $event);
  }

  clickAnchor(elName: string, $event: any): void {
    this.HtmlElementService.getElement(elName).subscribe((el) => {
      this.analyticsService.trackInput($event);
      this.generalService.scrollSnap(el, 1000, $event);
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.isBigScrn = this.generalService.isBigScrn;
    this.generalService.scrollSnap(this.heroHTML.nativeElement);
  }

  ngAfterViewChecked(): void {
    if (this.isBigScrn !== this.generalService.isBigScrn) {
      if (!this.isBigScrn && this.generalService.isBigScrn)
        this.scrollSnap();

      this.isBigScrn = this.generalService.isBigScrn;
    }
  }
}
