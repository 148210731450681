import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';

import { MyWorkModule } from './my-work/my-work.module';

import { NavComponent } from './../shared/nav/nav.component';
import { HeroComponent } from './hero/hero.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './../shared/footer/footer.component';

@NgModule({
  declarations: [
    HomeComponent,
    NavComponent,
    HeroComponent,
    ServicesComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    MyWorkModule,
  ],
  exports: [
    HomeRoutingModule
  ]
})

export class HomeModule { }
