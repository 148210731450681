import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarEntryComponent } from './sb-entry.component';

@NgModule({
  declarations: [
    SidebarEntryComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SidebarEntryComponent
  ]
})

export class SidebarEntryModule { }
