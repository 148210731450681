import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Entry } from 'contentful';

import { ctfConfig } from '@src/config/contentful.config';

import { AnalyticsService } from '@src/app/shared/services/fb-analytics.service';
import { GeneralService } from '@src/app/shared/services/general.service';
import { ContentService } from '@src/app/shared/services/content.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';

@Component({
  selector: 'nh-home-services',
  templateUrl: './my-services.component.html',
  styleUrls: ['./my-services.component.scss']
})
export class MyServicesComponent implements OnInit, AfterViewInit, OnDestroy {
  services: Entry<any>[] | null;
  serviceSub: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private generalService: GeneralService,
    private contentService: ContentService,
    private HtmlElementService: HtmlElementService,
    private servicesHTML: ElementRef
  ) { }
  
  clickAnchor(elName: string, $event: any): void {
    this.HtmlElementService.getElement(elName).subscribe((el) => {
      this.analyticsService.trackInput($event);
      this.generalService.scrollSnap(el, 1000, $event);
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.serviceSub = this.contentService.getEntries(
      'services',
      'sys.createdAt'
    )
    .subscribe((data) => {
      this.services = data;
    })
  }

  ngAfterViewInit(): void {
    this.HtmlElementService.setElement(
      'services',
      this.servicesHTML.nativeElement
    )
  }

  ngOnDestroy(): void {
    this.serviceSub.unsubscribe();
    this.HtmlElementService.deleteElement('services');
  }

}
