<section id="hw-sidebar">
	<div id="hws-wrap">
		<ng-template #default>
			<div [@entryState]="entryState" id="hw-sidebar-main">
				<div id="sidebar-top">
					<div class="striped-bg"></div>
					<form id="sidebar-search" role="search" method="get">
						<input #searchBox id="search-box" type="text" [value]="searchTerm.value" name="s" placeholder="Search for a project" autocomplete="off" autocorrect="off" (input)="searchEntries(searchBox.value)">
						<button id="search-clear" type="reset" (click)="this.searchEntries()">
							<img src="../../../assets/images/close-yellow-001.svg" width="30">
						</button>
					</form>
					<h2 id="sidebar-h2">Featured Projects</h2>
				</div>
				<div id="sidebar-content">
					<h3>&ldquo;I have no special talent. I am only passionately curious.&rdquo;<br /><span>&mdash; Some random genius</span></h3>
					<p>
						Filter by category
					</p>
					<form id="sidebar-bttns">
						<button [ngClass]="filterIdx === 0 ? 'active' : ''" id="dev" type="button" name="c" (click)="filterEntries($event, 'Email')">View Email</button>
						<button [ngClass]="filterIdx === 1 ? 'active' : ''" id="all" type="button" name="c" (click)="filterEntries($event)">View All</button>
						<button [ngClass]="filterIdx === 2 ? 'active' : ''" id="des" type="button" name="c" (click)="filterEntries($event, 'Web')">View Web</button>
					</form>
				</div>
			</div>
		</ng-template>
		<nh-sb-entry *ngIf="entry && sbEntryShow else default" [@entryState]="entryState" [entries]="entries" [entry]="entry"></nh-sb-entry>
	</div>
</section>
<div *ngIf="entry && !sbEntryShow" [@entryState]="entryState" id="sb-overlay" (click)="closeEntry($event);"></div>