<div class="striped-bg"></div>
<section id="home-services">
	<div class="section-main">
			<div id="home-serv-top">
				<h2>Let&rsquo;s work together</h2>
				<p>Here are a few ways that I can help you <br class="hide" />turn your ideas into reality:</p>
			</div>
			<ul *ngIf="services.length; else noServices" id="home-serv-main">
				<li *ngFor="let service of services; last as isLast;" class="serv-li">
					<div *ngIf="isLast" class="serv-mob-bg"></div>
					<div class="serv-img-wrap">
						<img [src]="service.fields.featuredImage.fields.file.url" />
					</div>
					<div class="serv-info">
						<h3 [innerHTML]="service.fields.title"></h3>
						<p [innerHTML]="service.fields.description"></p>
					</div>
				</li>
			</ul>
			<ng-template #noServices>
				<div id="no-serv">
					<p>Sorry, no services found!</p>
				</div>
			</ng-template>
			<div id="home-serv-bottom">
				<h2>Interested in learning more?</h2>
				<p>Then don&rsquo;t hesitate! Let&rsquo;s get the conversation started.</p>
				<a 
					class="button" 
					[routerLink]="['/']" 
					[routerLinkActive]="['active']" 
					[routerLinkActiveOptions]="{exact:true}" 
					fragment="contact" 
					[attr.data-content]="'Services Contact CTA'"
					(click)="clickAnchor('contact', $event); false;"
				>
					Contact
				</a>
				<p>Email: <strong>create@nhdsgn.com</strong></p>
			</div>
		</div>
</section>
