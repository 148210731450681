<div id="hw-sidebar-entry">
	<div id="sidebar-top">
		<div class="striped-bg"></div>
		<div id="title-nav">
			<a id="prev" href="#" (click)="navEntry(-1); false;"><img src="../../../assets/images/prev-yellow-001.svg" width="15"></a>
			<h2>{{ entry.fields.title }}</h2>
			<a id="next" href="#" (click)="navEntry(1); false;"><img src="../../../assets/images/next-yellow-001.svg" width="15"></a>
		</div>
	</div>
	<div id="sidebar-content">
		<h3>About</h3>
		<p id="sb-about" [innerHTML]="contentService.parseData(entry.fields.description)"></p>
		<a class="button" [href]="entry.fields.viewUrl" target="_blank">View More</a>
		<ul *ngIf="entry.fields.categories" id="sb-cats">
			<h3>Categories</h3>
			<li *ngFor="let cat of entry.fields.categories">
				{{ cat }}<br />
			</li>
		</ul>
		<ul *ngIf="entry.metadata.tags.length" id="sb-tags">
			<h3>Tags</h3>
			<li *ngFor="let tag of entry.metadata.tags">
				{{ tag }}
			</li>
		</ul>
	</div>
</div>