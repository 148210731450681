import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Entry } from 'contentful';

import { GeneralService } from '@src/app/shared/services/general.service';
import { ContentService } from '@src/app/shared/services/content.service';

@Component({
  selector: 'nh-mw-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('entryState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({
          opacity: 1
        })),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})

export class SidebarComponent implements OnInit, OnDestroy {
  @Input() entries: Entry<any>[];
  @Input() entry: Entry<any> | null;
  @Input() entryState: boolean;

  @Output() entryChange = new EventEmitter<object>();

  private searchTerm = new BehaviorSubject<string>('');

  entiresAll: Entry<any>[];
  entrySub: Subscription;
  
  gaSearchInit: boolean = false;
  filterIdx: number = 1;
  sbEntryShow: boolean;

  constructor(
    private generalService: GeneralService,
    private contentService: ContentService
  ) { }

  getEntries(): Subscription {
    return this.contentService.getEntries().subscribe(data =>
      this.entiresAll = data
    );
  }

  searchEntries(str?: string): void {
    this.searchTerm.next(str || '');
  }

  filterEntries($event: any, str?: string): void {
    const element = $event.target;

    this.contentService.filterEntries(this.entiresAll, str || '');

    if (this.entries) {
      Array.from(
        element.parentElement.children
      )
      .map((el: HTMLElement, i: number) => {
        if (element === el) {
          this.filterIdx = i;
        }
      });
    }
  }

  closeEntry($event?: any): void {
    if ($event && $event.currentTarget !== $event.target)
      return;

    this.entryChange.emit(null);
  }
  
  // Lifecycle Hooks

  ngOnInit(): void {
    this.getEntries();

    this.entrySub = this.contentService.entry.subscribe(
      data => this.entry = data
    );

    this.searchTerm.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((str: string) => 
        this.contentService.searchEntries(
          this.entiresAll, 
          str, 
          this.gaSearchInit
        )
      )
    )
    .subscribe(() => {
      this.gaSearchInit = true;
    });
  }

  ngOnDestroy(): void {
    this.getEntries().unsubscribe();
    this.entrySub.unsubscribe();
    this.searchTerm.unsubscribe();
    !this.gaSearchInit;
  }
}
