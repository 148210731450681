import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarComponent } from './sidebar.component';

import { SidebarEntryModule } from './sb-entry/sb-entry.module';

@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    SidebarEntryModule
  ],
  exports: [
    SidebarComponent
  ]
})

export class SidebarModule { }
