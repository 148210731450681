<section *ngIf="entry" class="entry" (click)="closeEntry($event, false);">
	<div #entryFull class="entry-full">
		<div class="entry-top">
			<div class="entry-nav">
				<a class="prev" href="#" (click)="navEntry(-1); false;"><img src="../../../assets/images/prev-gold-001.svg" width="12" alt="Previous Entry"></a>
				<a class="next" href="#" (click)="navEntry(1); false;"><img src="../../../assets/images/next-gold-001.svg" width="12" alt="Next Entry"></a>
			</div>
			<a class="entry-close" [attr.data-content]="entry.fields.title" href="#" (click)="closeEntry($event); false;"><img src="../../../../assets/images/close-gold-001.svg" width="40" alt="Entry Close"></a>
		</div>
		<div class="entry-content">
			<a class="entry-img" [attr.data-content]="entry.fields.title" [href]="entry.fields.viewUrl" target="_blank" (click)="analyticsService.trackInput($event);">
				<img [src]="entry.fields.thumbImage.fields.file.url" width="540" [alt]="entry.title" />
				<div class="view-entry">
					<p>Click to view</p>
				</div>
			</a>
			<h2>{{ entry.fields.title }}</h2>
			<p class="entry-about" [innerHTML]="contentService.parseData(entry.fields.description)"></p>
			<a class="button" [attr.data-content]="entry.fields.title" [href]="entry.fields.viewUrl" target="_blank" (click)="analyticsService.trackInput($event);">View More</a>
			<ul *ngIf="entry.fields.categories" class="entry-cats">
				<h3>Categories</h3>
				<li *ngFor="let cat of entry.fields.categories">
					{{ cat }}<br />
				</li>
			</ul>
			<ul *ngIf="entry.metadata.tags.length" class="entry-tags">
				<h3>Tags</h3>
				<li *ngFor="let tag of entry.metadata.tags">
					{{ tag }}
				</li>
			</ul>
		</div>
	</div>
</section>
