import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-four04',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.scss']
})
export class Four04Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
