import { Component, OnChanges, OnInit, OnDestroy, Input, ViewChildren, ElementRef, SimpleChanges, HostListener, Renderer2, QueryList, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Entry } from 'contentful';
import Isotope from 'isotope-layout';

import { ContentService } from '@src/app/shared/services/content.service';
import { GeneralService } from '@src/app/shared/services/general.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';

@Component({
  selector: 'nh-mw-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.scss'],
  animations: [
    trigger('entriesState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({
          opacity: 0
        }))
      ])
    ]),
    trigger('entryState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ 
          opacity: 1 
        })),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ 
          opacity: 0 
        }))
      ])
    ])
  ]
})

export class EntriesComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChildren('entryLI') entryLiHTML!: QueryList<ElementRef>;
  @ViewChild('entryFull', { static: false, read: ElementRef }) entryHTML: ElementRef;

  @Input() entries: Entry<any>[];
  @Input() entryState: boolean;

  entry: Entry<any> | null;
  entrySub: Subscription;
  entryElSub: Subscription

  entryTop: number;
  
  isotope: Isotope;

  constructor(
    private rend: Renderer2,
    private contentService: ContentService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService,
    private entriesHTML: ElementRef
  ) { }

  @HostListener(
    'resize', ['$event']
  )
  @HostListener(
    'scroll', ['$event']
  )
  handleEvents(): void {
    this.setEntriesTop();
  }

  async initIso(value?: any): Promise<void> {
    if (value)
      await value;

    this.isotope = new Isotope(
      `.${this.entriesHTML.nativeElement.classList[0]} ul`, 
      {
        itemSelector: 'li',
        layoutMode: 'masonry',
        initLayout: true,
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer'
        }
      }
    );
  }

  async arrangeIso(value: any): Promise<void> {
    await value;
    this.isotope.reloadItems();
    this.isotope.arrange();
  }

  selectEntry(id: string, $event?: any): Subscription {
    return this.contentService.getEntry(id).subscribe(() => {
      if (!this.generalService.isBigScrn) {
        const scrollPos = window.scrollY,
              winHeight = window.innerHeight,
              entriesRecs = this.entriesHTML.nativeElement.getClientRects()[0],
              firstEntRecs = this.entryLiHTML.first.nativeElement.getClientRects()[0],
              lastEntRecs = this.entryLiHTML.last.nativeElement.getClientRects()[0];

        if (!this.entryState && this.generalService.isMobScrn) 
          this.rend.setStyle(document.body, 'overflow', 'hidden');

        if (firstEntRecs.top > 0 || entriesRecs.height < winHeight) 
          return this.generalService.scrollSnap(
            null, 
            200, 
            $event, 
            scrollPos + firstEntRecs.top
          );

        if (lastEntRecs.bottom < winHeight) 
          this.generalService.scrollSnap(
            null, 
            200, 
            $event, 
            scrollPos + lastEntRecs.bottom - winHeight
          );
      }
    });
  }

  setEntriesTop(): void {
    const entriesRecs = this.entriesHTML.nativeElement.getClientRects()[0],
          entryLiRecs = this.entryLiHTML.first.nativeElement.getClientRects()[0];
          
    this.entryTop = -(entryLiRecs.top - entriesRecs.top);
  }

  // Lifecycle Hooks

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entryState) {
      this.entryElSub = this.HtmlElementService.getElement('entry').subscribe((el) => {
        const elFullHeight = el.getElementsByClassName('entry-full')[0]['offsetHeight'],

              elVertPad = (
                Number.parseInt(window.getComputedStyle(el).paddingTop) + 
                Number.parseInt(window.getComputedStyle(el).paddingBottom) || 0
              );

        this.rend.setStyle(
          this.entriesHTML.nativeElement, 
          'min-height', 
          `${elFullHeight + elVertPad}px`
        );
      })
    }

    if (!this.entryState) {
      this.rend.removeStyle(this.entriesHTML.nativeElement, 'min-height');
      this.entryElSub?.unsubscribe();
    }

    if (changes.entries) {
      const entries = changes.entries,
            prev = entries.previousValue,
            curr = entries.currentValue;

      if (!this.isotope && curr.length) {
        this.initIso(curr);
      }

      if (this.isotope && !curr.length) {
        this.isotope = null;
      }

      if (this.isotope && prev !== curr) {
        this.arrangeIso(curr);
      }
    }
  }

  ngOnInit(): void {
    this.entrySub = this.contentService.entry.subscribe(
      data => this.entry = data
    );
  }
  
  ngOnDestroy(): void {
    this.selectEntry(this.entry.sys.id).unsubscribe();
    this.entrySub.unsubscribe();
    this.entryElSub?.unsubscribe();
    this.isotope.destroy();
  }
}
