import { Component, OnInit, AfterViewInit, OnDestroy, Input, HostBinding, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AnalyticsService } from '../services/fb-analytics.service';
import { GeneralService } from '../services/general.service';
import { HtmlElementService } from '../services/html-element.service';

@Component({
  selector: 'nh-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  animations: [
    trigger('cookieState', [
      state('show', style({
        opacity: 1,
        visibility: 'visible'
      })),
      state('hide', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      transition('show <=> hide', animate('450ms ease-out'))
    ])
  ]
})
export class CookieBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('@cookieState') cbState: string = 'hide';

  @ViewChild('cookieMain') cookieMainHTML: ElementRef;
  
  cbDelay: number = 2000;

  constructor(
    private rend: Renderer2,
    private analyticsService: AnalyticsService,
    private HtmlElementService: HtmlElementService,
    private generalService: GeneralService,
    private cookieHTML: ElementRef
  ) { }

  async setCookies(cookies: boolean): Promise<void> {
    const consentStr = cookies ? 'gr' : 'dn';

    await this.analyticsService.setCookie(`consent_${consentStr}`);

    if (cookies) {
      this.analyticsService.updateConsent('granted', 'update');
    } else {
      this.analyticsService.updateConsent('denied', 'update');
    }

    this.cbState = 'hide';

    setTimeout(() => {
      if (this.cbState === 'hide') 
        this.analyticsService.cbState = false;
    }, 450)
  }

  // Lifecycle Hooks

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const cookEl = this.cookieHTML.nativeElement,
          cookMainEl = this.cookieMainHTML.nativeElement;

    this.HtmlElementService.setElement(
      'cookie',
      cookEl
    );

    this.rend.listen(
      cookMainEl, 
      'animationend', 
      ($event) => {
        this.rend.removeClass($event.target, 'anim-start');
      }
    );

    this.rend.addClass(cookMainEl, 'anim-start');

    this.rend.setStyle(
      cookMainEl,
      'animation-delay',
      `${this.cbDelay}ms`
    );

    setTimeout(() => {
      this.cbState = 'show';
    }, this.cbDelay)
  }

  ngOnDestroy(): void {
    this.HtmlElementService.deleteElement('cookie');
    this.rend.destroy();
  }
}
