import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// import { rcConfig } from '@src/config/recaptcha.config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { WordLimitPipe } from './shared/pipes/word-limit.pipe';
import { CharLimitPipe } from './shared/pipes/char-limit.pipe';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';

import { HomeModule } from './home/home.module';
import { Four04Component } from './404/four04.component';

@NgModule({ declarations: [
        AppComponent,
        WordLimitPipe,
        CharLimitPipe,
        SafeHtmlPipe,
        Four04Component
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        // RecaptchaV3Module,
        AppRoutingModule,
        HomeModule], providers: [
        // { provide: RECAPTCHA_V3_SITE_KEY, useValue: rcConfig.siteKey },
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }
