import { ApplicationRef, ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HtmlElementService {
  protected elements: any = {};

  constructor(
    private app: ApplicationRef
  ) { }

  getElement(key: string): BehaviorSubject<HTMLElement> {
    if (key === 'root' || key === 'top') {
      return new BehaviorSubject(
        this.app.components[0].injector.get(ElementRef).nativeElement
      )
    }

    if (!this.has(key)) {
      this.elements[key] = new BehaviorSubject(null);
    }

    return this.elements[key].pipe(
      filter((el) => el != null)
    );
  }

  setElement(key: string, value: HTMLElement): BehaviorSubject<HTMLElement> {
    if (this.has(key)) {
      this.elements[key].next(value);
    } else {
      this.elements[key] = new BehaviorSubject(value);
    }

    return this.elements[key];
  }

  deleteElement(key: string): void {
    if (this.has(key)) {
      this.elements[key].next(null);
      delete this.elements[key];
    }
  }

  has(key: string) {
    return (this.elements[key] instanceof BehaviorSubject);
  }
}
