import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntriesComponent } from './entries.component';

import { EntryModule } from './entry/entry.module';

@NgModule({
  declarations: [
    EntriesComponent
  ],
  imports: [
    CommonModule,
    EntryModule
  ],
  exports: [
    EntriesComponent
  ]
})

export class EntriesModule { }
