import { Component, Input, OnInit } from '@angular/core';
import { Entry } from 'contentful';

import { ContentService } from '@src/app/shared/services/content.service';

@Component({
  selector: 'nh-sb-entry',
  templateUrl: './sb-entry.component.html',
  styleUrls: ['./sb-entry.component.scss']
})

export class SidebarEntryComponent implements OnInit {
  @Input() entries: Entry<any>[];
  @Input() entry: Entry<any> | null;

  constructor(
    protected contentService: ContentService
  ) { }

  navEntry(num: number): void {
    if (this.entries.length === 1) 
      return;
      
    this.contentService.getEntry(null).subscribe();
    
    setTimeout(() => {
      this.contentService.navEntry(this.entries, this.entry, num);    
    }, 600);
  }

  // Lifecycle Hooks

  ngOnInit(): void {
  }
}
