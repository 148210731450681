import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from '../home-routing.module';
import { MyWorkComponent } from './my-work.component';

import { EntriesModule } from './entries/entries.module';
import { SidebarModule } from './sidebar/sidebar.module';

@NgModule({
  declarations: [
    MyWorkComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    EntriesModule,
    SidebarModule
  ],
  exports: [
    MyWorkComponent
  ]
})

export class MyWorkModule { }
