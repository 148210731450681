import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent, EventParams, setConsent, ConsentStatusString } from 'firebase/analytics';

import { environment } from '@src/environments/environment';
import { fbConfig } from '@src/config/firebase.config';

const app = initializeApp(fbConfig);

@Injectable({
	providedIn: 'root'
})

export class AnalyticsService {
	analyticsEnabled: boolean = environment.analyticsEnabled;
	cookies: string[] = document.cookie.split(';') || [];

	cbState: boolean = true;

	analytics(): Analytics {
		if (this.cookieName('consent'))
			this.cbState = false;

		return getAnalytics(app);
	}

	async setCookie(prefix: string): Promise<void> {
		const gtag = window['gtag'] || null;

		if (gtag) {
			await gtag(
				'config', 
				fbConfig.measurementId, 
				{
					cookie_prefix: prefix,
					cookie_domain: environment.cookieDomain,
					cookie_expires: 60 * 60 * 24 * 28,
					cookie_update: false
				}
			);
		}
	}

	cookieName = (name: string): boolean => {
		return this.cookies.some((item) =>
			item.trim().startsWith(name)
		)
	};

	updateConsent(status: ConsentStatusString, statusType?: string): void {
		const settings = {
			ad_storage: status,
			analytics_storage: status,
			ad_user_data: status,
			ad_personalization: status
		},

		gtag = window['gtag'] || null;
		
		if (statusType === 'update' && gtag) {
			gtag('consent', settings);
			return;
		}

		setConsent(settings);
	} 

	logEvent(event: string, eventParams: EventParams): void {
		if (!this.analyticsEnabled) 
			return;

		return logEvent(
			this.analytics(), 
			event, 
			eventParams
		);
	}

	trackInput($event: any): void {
		const targ = $event.target;

		this.logEvent(
			'user_input',
			{
				input_type: $event.type,
				js_name: $event.constructor.name,
				value:
					targ.dataset.content ||
					targ.parentNode.dataset.content ||
					targ.value ||
					targ.href ||
					targ.outerHTML ||
					targ.alt ||
					'N/A',
				description:
					targ.innerText ||
					targ.name ||
					targ.alt ||
					targ.placeholder ||
					targ.dataset.content ||
					'input'
			}
		)
	}
}