import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';

// import { GoogleAuth, OAuth2Client } from 'google-auth-library';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

// import { rcConfig } from '@src/config/recaptcha.config';

import { Message } from '@src/app/shared/interfaces/message';

import { AnalyticsService } from 'src/app/shared/services/fb-analytics.service';
import { GeneralService, debounce } from '@src/app/shared/services/general.service';
import { MessageService } from '@src/app/shared/services/message.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';

// import { keys } from '@src/assets/js/gcloud_api/oauth2.keys';

@Component({
  selector: 'nh-home-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('submitState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({
          opacity: 1
        })),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})

export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
  message?: Message;
  messageSub: Subscription;

  // recaptchaSub: Subscription;

  submitState: boolean = false;
  successState: boolean = false;
  failureState: boolean = false;
  
  contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    subject: ['', Validators.required],
    msgBody: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    // private rcV3Service: ReCaptchaV3Service,
    protected analyticsService: AnalyticsService,
    protected generalService: GeneralService,
    private messageService: MessageService,
    private HtmlElementService: HtmlElementService,
    private contactHTML: ElementRef
  ) { }


  @debounce(750)
  trackTxtInput($event: any): void {
    this.analyticsService.trackInput($event);
  }

  sendMessage($event: any): void {
    const // rcExec = this.rcV3Service.execute(rcConfig.expectedAction),

          sendMsg = (val) => this.messageService.sendMessage(val);
    
    /* this.recaptchaSub = rcExec.subscribe(
      (token) => { */
        if (this.contactForm.valid && !this.submitState) {
          const formValue = this.contactForm.value;

          this.analyticsService.trackInput($event);

          if (this.messageSub)
            this.messageSub.unsubscribe();

          this.submitState = true;

          this.message = {
            replyTo: formValue.email,
            subject: `New Message from ${formValue.name}: ${formValue.subject}`,
            text: formValue.msgBody,
            html: formValue.msgBody
          }

          this.messageSub = sendMsg(this.message).subscribe((res) => {
            this.submitState = false;

            if (res.error)
              return this.failureState = true;

            this.successState = true;
            this.contactForm.reset();
          }
          );
        }
      /* }, 
      (error) => {
        console.error('Recaptcha v3 error:', error);
        this.failureState = true;
      }
    ) */
  }

  closeConf($event): void {
    this.analyticsService.trackInput($event);

    if (this.messageSub)
      this.messageSub.unsubscribe();
      
    if (this.submitState)
      this.submitState = false;

    if (this.successState)
      this.successState = false;

    if (this.failureState)
      this.failureState = false;
  }

  // Lifecycle Hooks

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.HtmlElementService.setElement(
      'contact',
      this.contactHTML.nativeElement
    )
  }

  ngOnDestroy(): void {
    // this.recaptchaSub.unsubscribe();
    this.messageSub.unsubscribe();
  }
}
