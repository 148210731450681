import { Component, Input, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, Output, EventEmitter, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Entry } from 'contentful';

import { AnalyticsService } from '@src/app/shared/services/fb-analytics.service';
import { ContentService } from '@src/app/shared/services/content.service';
import { GeneralService } from '@src/app/shared/services/general.service';
import { HtmlElementService } from '@src/app/shared/services/html-element.service';

@Component({
  selector: 'nh-mw-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})

export class EntryComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('entryFull') entryHTML!: ElementRef;

  @Input() entries: Entry<any>[];
  @Input() entry: Entry<any> | null;

  @Output() entryChange = new EventEmitter<object>();

  entryTop: number;

  constructor(
    private rend: Renderer2,
    private contentService: ContentService,
    private analyticsService: AnalyticsService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService
  ) { }
  
  @HostListener(
    'window:resize', ['$event']
  )
  handleResize(): void {
    this.setEntryTop();

    if (this.entry)
      this.generalService.vhFix100(this.entryHTML.nativeElement);
  }

  setEntryTop(): void {
    const entryMobRecs = this.entryHTML?.nativeElement.getClientRects()[0];

    this.entryTop = (window.innerHeight - this.entryHTML.nativeElement.offsetHeight) / 2;

    this.rend.setStyle(
      this.entryHTML.nativeElement, 
      'top', 
      this.entryTop + 'px'
    )

    if (this.entry && this.generalService.isMobScrn) {
      if (entryMobRecs?.top === 0) {
        this.rend.setStyle(document.body, 'overflow', 'hidden');
        return;
      }

      this.entryHTML.nativeElement.scrollIntoView();
    }

    this.rend.removeStyle(document.body, 'overflow');
  }

  navEntry(num: number): void {
    if (this.entries.length === 1)
      return;

    this.contentService.navEntry(this.entries, this.entry, num);
  }

  closeEntry($event: any, bubble: boolean = true): void {
    if (
      !bubble &&
      $event.currentTarget !== $event.target
    ) 
      return;

    this.analyticsService.trackInput($event);

    if (document.body.style.overflow === 'hidden') 
      this.rend.removeStyle(document.body, 'overflow');
    
    this.entryChange.emit(null);
  }

  // Lifecycle Hooks

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.HtmlElementService.setElement(
      'entry',
      this.entryHTML.nativeElement.parentElement
    )
  }

  ngAfterViewChecked(): void {
    if (this.entry) 
      this.generalService.vhFix100(this.entryHTML.nativeElement);

    if (!this.entryTop) 
      this.setEntryTop();
  }

  ngOnDestroy(): void {
    !this.entryTop; 
  }
}
