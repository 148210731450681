<section id="cookie-banner">
	<div #cookieMain class="section-main">
		<div id="cookie-top">
			<a class="cookie-close" href="#" (click)="setCookies(true); false;"><img src="../../../../assets/images/close-blk-001.svg" width="40" alt="Cookie Close"></a>
		</div>
		<div id="cookie-bottom">
			<div id="cookie-content">
				<h2>That <span class="wrap">cookie </span><span class="no-wrap">talk.&nbsp;<img src="../../../../assets/images/cookie-02.svg" width="50" alt="Cookie talk" /></span></h2>
				<p>
					We use cookies on this site to analyze site traffic and enhance your experience. By clicking <strong>&ldquo;Accept Cookies&rdquo;</strong> or closing this banner, you're consenting to our use of cookies. If you prefer not to allow cookies (outside of the absolutely necessary ones of course), you can opt out by clicking <strong>&ldquo;Reject Cookies&rdquo;</strong>. <br /><br />
					Welcome &mdash; please make yourself at home!
				</p>
				<div id="cookie-buttons">
					<a class="button" id="cbtn-first" href="#" (click)="setCookies(true); false;">Accept Cookies</a>
					<a class="button" id="cbtn-last" href="#" (click)="setCookies(false); false;">Reject Cookies</a>
				</div>
			</div>
			<img id="ck-main" src="../../../../assets/images/cookie-01-v3.svg" width="200" alt="Cookie talk" />
		</div>
	</div>
</section>
