import { Component, OnInit } from '@angular/core';

import { AnalyticsService } from './shared/services/fb-analytics.service';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(
    protected analyticsService: AnalyticsService
  ) { } 

  ngOnInit(): void {
    if (this.analyticsService.cookieName('consent_dn')) {
      this.analyticsService.updateConsent('denied');
    } else {
      this.analyticsService.updateConsent('granted');
    }

    this.analyticsService.analytics();
  }
}
