import { Component, OnInit, AfterViewChecked, OnDestroy, ElementRef, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { AnalyticsService } from '../services/fb-analytics.service';
import { GeneralService } from '../services/general.service';
import { HtmlElementService } from '../services/html-element.service';

@Component({
  selector: 'nh-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('nav') navHTML!: ElementRef;

  navState: boolean = false;
  cssTop: number = 0;
  navShowEl: HTMLElement;
  navShowElSub: Subscription;
  isBigScrn: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private generalService: GeneralService,
    private HtmlElementService: HtmlElementService
  ) { }

  @HostListener (
    'window:scroll', ['$event']
  ) 
  handleScroll(): void {
    this.stickyNav();
  }

  stickyNav(): void {
    const trigger = () => {
      const elRef = this.navShowEl,
            pos = elRef.offsetTop,
            navEl = this.navHTML.nativeElement,
            navHeight = navEl.offsetHeight,
            navMargin = Number.parseInt(window.getComputedStyle(navEl).marginTop);
      
      if (window.scrollY >= pos - 1) {
        this.navState = true;
        this.cssTop = 0;
      } else {
        this.navState = false;
        this.cssTop = -navHeight - navMargin;
      }
    };

    if (
      this.navShowEl &&
      this.isBigScrn === this.generalService.isBigScrn
    ) {
      trigger();
      return;
    }
      
    if (this.navShowElSub) {
      this.navShowElSub.unsubscribe();
      !this.navShowElSub;
    }

    this.isBigScrn = this.generalService.isBigScrn;

    this.navShowElSub = this.HtmlElementService.getElement(
      'my-work'
    )
    .subscribe(el => {
      this.navShowEl = el;
      trigger();
    });
  }

  clickAnchor(elName: string, $event: any): void {
    this.HtmlElementService.getElement(elName).subscribe((el) => {
      this.analyticsService.trackInput($event);
      this.generalService.scrollSnap(el, 1000, $event);
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.stickyNav();
  }

  ngAfterViewChecked(): void {
    this.stickyNav();
  }

  ngOnDestroy(): void {
    this.navShowElSub.unsubscribe();
  }
}