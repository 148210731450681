import { Injectable } from '@angular/core';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { createClient, Entry } from 'contentful';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import DOMPurify from 'dompurify';
import { parse } from 'angular-html-parser';

import { ctfConfig } from '@src/config/contentful.config';
import { CustomEntry } from '../interfaces/customEntry';
import { AnalyticsService } from './fb-analytics.service';

@Injectable({
  providedIn: 'root'
})

export class ContentService {
  private config = ctfConfig;

  private cdaClient = createClient({
    space: this.config.space,
    accessToken: this.config.accessToken
  });

  private d2hOpt: object = {
    renderMark: {
      [MARKS.CODE]: text => text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => `<img src="${(node.data.target.fields.file.url)}" width="100%" />`
    }
  };

  private entrySubject = new BehaviorSubject<Entry<any>>(null);
  private entriesSubject = new BehaviorSubject<Entry<any>[]>([]);
  private entryStateSubject = new BehaviorSubject<boolean>(false);
  private searchSubject = new BehaviorSubject<Entry<any>[]>(null);
  private filterSubject = new BehaviorSubject<Entry<any>[]>(null);

  entry = this.entrySubject.asObservable();
  entries = this.entriesSubject.asObservable();
  entryState = this.entryStateSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      
      if (result)
        return of(result as T);

      return of(error as T);
    };
  }
  
  docToHTML(doc: any, opt?: any): any {
    if (!opt)
      return documentToHtmlString(doc, this.d2hOpt);
      
    return documentToHtmlString(doc, opt);
  }

  parseData(data: any): any {
    const parser = (val) => {
      return parse(DOMPurify.sanitize(val));
    },

    parseStr = (obj) => {
      if (typeof obj === 'object') {
        Object.keys(obj).map((key) => {
          if (typeof obj[key] === 'string') {
            obj[key] = parser(obj[key]);
          } else {
            parseStr(obj[key]);
          }
        })
      };

      return obj;
    };

    return parseStr(data);
  }

  getEntries(contentType?: string, order?: string, query?: object): Observable<Entry<any>[]> {
    return from(this.cdaClient.getEntries(
      Object.assign({
        content_type: contentType || this.config.contentTypeIDs.entries,
        order: order || '-sys.createdAt'
      }, query)
    ))
    .pipe(
      map(res => {
        if (!contentType || contentType === 'entries') {
          this.entriesSubject.next(res.items);
        }

        return res.items;
      }),
      catchError(this.handleError<Entry<any>[]>([]))
    )
  }

  getEntry(id: string | null, contentType?: string, gaEvent: boolean = true): Observable<Entry<any>> | Observable<null> {
    if (!id) {
      return of(null).pipe(
        map(res => {
          if (!contentType || contentType === 'entries') {
            this.entrySubject.next(null);
            this.entryStateSubject.next(false);
          }

          return res;
        })
      );
    }

    return from(this.cdaClient.getEntry(
      id,
      Object.assign({
        content_type: (
          contentType ? 
          this.config.contentTypeIDs[contentType] :
          this.config.contentTypeIDs.entries
        )
      })
    ))
    .pipe(
      map((res: CustomEntry) => {
        if (!contentType || contentType === 'entries') {
          this.entrySubject.next(res);
          this.entryStateSubject.next(true);
        }

        if (gaEvent) {
          this.analyticsService.logEvent(
            'select_item',
            {
              item_list_id: res.sys.contentType.sys.id,
              item_list_name: res.sys.type || 'entries',
              items: [
                {
                  item_id: res.sys.id,
                  item_name: res.fields.title
                }
              ]
            }
          );
        }

        return res;
      }),
      catchError(this.handleError<Entry<any>>(null))
    )
  }

  searchEntries(entries: Entry<any>[], str: string, gaEvent: boolean = true): Observable<Entry<any>[]> {
    const term = str.toLowerCase(),
          results = entries.filter((el) => {
            const criteria = (
              el.metadata.tags.join(' ') + 
              el.fields.title +
              el.fields.description +
              (el.fields.categories || ['']).join(' ')
            ).toLowerCase();

            if (criteria.includes(term)) {
              return el;
            }
          });

    return of(results).pipe(
      tap((data) => {
        this.searchSubject.next(data);

        if (gaEvent) {
          this.analyticsService.logEvent(
            'search',
            {
              search_term: str
            }
          );
        }

        this.mergeQuery(
          this.filterSubject, 
          this.searchSubject,
          data
        );
      })
    )
  }

  filterEntries(entries: Entry<any>[], str: string, gaEvent: boolean = true): void {
    const term = str.toLowerCase(),
          results = entries.filter((el) => {
            const criteria = (el.fields.categories || ['']).join(' ').toLowerCase();
            
            if (criteria.includes(term)) {
              return el;
            }
          });

    this.filterSubject.next(results);

    if (gaEvent) {
      this.analyticsService.logEvent(
        'select_content',
        {
          content_type: str
        }
      );
    }
    
    this.mergeQuery(
      this.searchSubject, 
      this.filterSubject,
      results
    );
  }

  mergeQuery(subj: BehaviorSubject<Entry<any>[]>, subj2: BehaviorSubject<Entry<any>[]>, defaultRes: Entry<any>[]): void {
    const query = subj.value,
          query2 = subj2.value;

    if (query) {
      this.entriesSubject.next(query.filter(el =>
        query2.includes(el)
      ));
    } else {
      this.entriesSubject.next(defaultRes);
    }
  }

  navEntry(entries: Entry<any>[], entry: Entry<any> | null, num: number): void {
    if (entry) {
      const eLength = entries.length,
            currIndex = entries.findIndex(obj => {
              return obj.sys.id === entry.sys.id;
            }),

            newIndex = (): number => {
              if (num > 0)
                return (currIndex + num) % eLength;

              if (num < 0) {
                if (-num >= eLength)
                  return (
                    currIndex - (-num % eLength) + eLength
                  ) % eLength;

                return (
                  currIndex + num + eLength
                ) % eLength;
              }

              return currIndex;
            },

            nextEntryID = entries[newIndex()].sys.id;

      this.getEntry(nextEntryID).subscribe();
    }
  }
}
