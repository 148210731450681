<div class="striped-bg"></div>
<section id="home-contact">
	<div class="section-left">
		<div id="ci-wrapper">
			<div id="contact-info">
				<h2>Get in touch</h2>
				<p>Questions? Or maybe you&rsquo;d like to discuss a project and/or get a quote? Feel free to reach out to me via the
					contact form<span id="contact-dir"></span>, or shoot me an email if you prefer!<br /><br /><br />
					<a href="mailto:create@nhdsgn.com">create@nhdsgn.com</a>
				</p>
				<ul id="contact-social">
					<li><a href="https://www.linkedin.com/in/nickhudson1" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/linkedin-orange-001.svg" width="50" alt="LinkedIn"></a></li>
					<li><a href="https://www.instagram.com/nickhudsondev/" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/instagram-orange-001.svg" width="50" alt="Instagram"></a></li>
					<li><a href="https://www.behance.net/nickhudson86" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/behance-orange-001.svg" width="50" alt="Behance"></a></li>
					<li><a href="https://assets.ctfassets.net/1sl9m3guoua9/55VKR61YMr6sfC5HlvLXJW/72e6ce3d83a689570b8716ea565882e7/Nick-Hudson-Resume-2311.pdf" target="_blank" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/resume-orange-001.svg" width="50" alt="My R&eacute;sum&eacute;"></a></li>
				</ul>
			</div>
		</div>
	</div>
	<div class="section-right">
		<div id="cf-wrapper">
			<div id="contact-form">
				<h3>Drop a line, not a dime</h3>
				<form [formGroup]="contactForm">
					<div id="contact-namemail">
						<input [attr.data-content]="'Contact Name'" type="text" formControlName="name" placeholder="Name" (keydown)="trackTxtInput($event);">
						<input [attr.data-content]="'Contact Email'" type="email" formControlName="email" placeholder="Email" (keydown)="trackTxtInput($event);">
					</div>
					<input [attr.data-content]="'Contact Subject'" type="text" formControlName="subject" placeholder="Subject" (keydown)="trackTxtInput($event);">
					<textarea formControlName="msgBody" [attr.data-content]="'Contact Message'" placeholder="Your message" (keydown)="trackTxtInput($event);"></textarea>
					<div id="contact-submit">
						<button id="form-reset" type="reset" [disabled]="!contactForm.dirty" (click)="analyticsService.trackInput($event);"><img src="../../../assets/images/close-dark-tan-001.svg" width="30" alt="Contact Form Reset"></button>
						<button id="form-submit" type="submit" [disabled]="!contactForm.valid" (click)="sendMessage($event); false;">Send</button>
					</div>
				</form>
			</div>
			<div 
				*ngIf="submitState || successState || failureState" 
				[@submitState]="submitState || successState || failureState" 
				id="cf-success"
			>
				<div id="cfs-main">
					<a class="cfs-close" href="#" (click)="closeConf($event); false;"><img src="../../../../assets/images/close-gold-001.svg" width="40" alt="Contact Form Confirmation Close"></a>
					<div class="clearfix"></div>
					<p *ngIf="submitState" id="cfs-message">Sending&hellip;</p>
					<p *ngIf="successState" id="cfs-message">Your message has been sent!</p>
					<p *ngIf="failureState" id="cfs-message">Sorry, something went wrong. Please try again later.</p>
				</div>
			</div>
		</div>
	</div>
</section>